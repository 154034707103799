body {
  text-align: center;
}

svg text {
  font-family: arial, sans-serif;
  font-size: 12px;
}

.button_area rect:hover {
  stroke: #555;
}

.button_area text {
  pointer-events: none;
}

.chart_area rect:hover {
  stroke: #555;
  stroke-width: 1px;
  stroke-dasharray: 8 2;
}

text.hover_indname {
  font-weight: bold;
}
