svg.svg_treemap {
  overflow: hidden;
}

#map {
  z-index: 0;
  border-radius: 5px;
  border: 1px solid #aaa;
}

.info {
    padding: 6px 8px;
    font: 14px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.7);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
}

.info h4 {
    margin: 0 0 5px;
    color: #444;
}

g.treemap_group {
  overflow:hidden;
}

text.treemap_title {
  font-size: 14px;
  font-weight: bold;
}

text.treemap_label {
  font-size: 12px;
  font-weight: bold;
}

text.treemap_value {
  font-size: 11px;
}

div.svg_and_map {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width:1300px) {
  div.svg_and_map {
    width: 90%;
    justify-content: center;
    gap: 30px;
  }
}
