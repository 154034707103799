.App {
  text-align: center;
}

a {
  color: inherit;
}

div.spacer {
  width: 100%;
  height: 30px;
  background-color: inherit;
}

div.menu_container {
  width: 100%;
  height: 75px;
  background-color: #2c435e;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 2;
}

div.menu {
  width: 80%;
  max-width: 1000px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2c435e;
  margin: 0 auto;
}

span.topbar_name {
  font-family: arial, helvetica, sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: #fdfefe;
  background-color: #2c435e;
}

ul.menu-list {
  width: auto;
  list-style-type: none;
  padding: 5px 0px 10px 0px;
  overflow: hidden;
  background-color: #2c435e;
}

li.menu-list-item {
  display: inline;
  padding: 10px 20px;
  background-color: #2c435e;
  border-bottom: 1px solid #2c435e;
  font-family: arial, helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #fdfefe;
}

div.menu a {
  text-decoration: none;
}

.hamb {
    cursor: pointer;
    float: right;
    padding: 20px 0px;
    display: none;
}

.hamb-line {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
}

.hamb-line::before,
.hamb-line::after{
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
.hamb-line::before{
    top: 7px;
}
.hamb-line::after{
    top: -7px;
}

.side-menu {
    display: none;
} /* Hide checkbox */

.side-menu:checked ~ .menu-list {
    width: 100%;
    max-height: 210px;
}

.side-menu:checked ~ .hamb .hamb-line {
    background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
    transform: rotate(-45deg);
    top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
    transform: rotate(45deg);
    top:0;
}

@media (max-width: 1600px) {
  span.topbar_name {
    display: inline-block;
    font-size: 24px;
  }

  ul.menu-list {
    position: fixed;
    top: 75px;
    left: 0px;
    width: 0px;
    height: 210px;
    margin: 0;
    max-height: 0;
    transition: max-height .3s ease-out;
  }

  li.menu-list-item {
    margin: 0 auto;
    width: 30%;
    display: block;
  }

  .hamb {
    display: inline-block;
  }

}

@media (max-width: 1000px) {
  div.menu {
    width: 80%;
  }
}

li.menu-list-item:hover {
  border-bottom: 1px solid #fdfefe;
}

div.bottom_bar {
  width: 100%;
  height: 75px;
  background-color: #2c435e;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.bottom_bar > p {
  font-size: 18px;
  color: #fdfefe;
}

div.section_ID {
  height: 10px;
}

div.header_statement {
  color: #2c435e;
  width: 100%;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  font-family: arial;
  text-align: left;
}

div.header_left {
  text-align: left;
}

div.header_right {
  text-align: right;
}

div.header_substatement {
  width: 100%;
  margin: 20px auto 50px auto;
  font-size: 18px;
  font-family: arial;
  line-height: 30px;
}

div.header_block {
  width: 100%;
  margin: 0px auto 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

span.section_header {
  margin: 0px 30px 0px 0px;
  padding: 0px;
  text-align: center;
  border-radius: 5px;
  color: #2c435e;
}

span.section_header > p {
  font-size: 32px;
  font-weight: bold;
  background-color: inherit;
  margin: 0px;
}

span.section_subheader {
  width: 100%;
  max-width: 750px;
  margin-left: auto;
  text-align: justify;
  font-size: 18px;
  font-family: arial;
  line-height: 30px;
}

@media (max-width: 1080px) {
  span.section_header {
    margin: 0px 30px 10px 30px;
  }

  span.section_subheader {
    width: 90%;
    margin-left: 0px;
    text-align: center;
  }
}

#splash_background {
  color: #2c435e;
}

div.titles {
  width: 80%;
  max-width: 250px;
  margin-left: -125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: arial, sans-serif;
  font-size: 24px;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 240px;
  left: 50%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

div.titles p {
  margin: 10px 10px;
  padding: 20px;
  opacity: 0.95;
  border-radius: 5px;
  background-color: #fafafa;
}

div.skill_highlights {
  width: 95%;
  max-width: 320px;
  margin: 30px auto;
  padding: 0px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: courier;
  font-size: 20px;
  pointer-events: none;
  position: relative;
  z-index: 1;
  opacity: 0.95;
  border-radius: 5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

div.skill_highlights p {
  margin: 20px 5px;
}

div.skill_highlights ul {
  max-width: 240px;
  text-align: left;
  list-style-type: none;
  list-style-position: outside;
  margin: 10px 20px;
  padding: 0px;
  font-size: 17px;
  pointer-events: none;
}

div.splash_visualization {
  margin-top: 100px;
  z-index: 0;
}

#about_background {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

div.intro_text {
  width: 100%;
  height: auto;
  font-family: arial, helvetica, sans-serif;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

div.intro_text > img {
  width: 250px;
  height: auto;
  margin: 50px;
  border-radius: 5px;
}

div.intro_text > div > p {
  width: 100%;
  max-width: 650px;
  height: auto;
  margin: 20px 0px;
  padding: 0px;
}

#skills_background {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

div.skills {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

div.skills > div {
  width: 47%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.skills > div:nth-child(odd) {
  margin-right: 3%;
}

div.skills > div:nth-child(even) {
  margin-left: 3%;
}

div.skills .icon {
  color: #fdfefe;
  padding: 15px;
  background-color: #2c435e;
  border-radius: 5px;
}

div.skills > div > span:nth-child(2) {
  padding-left: 20px;
  font-size: 16px;
  text-align: left;
}

@media (max-width: 1000px) {
  div.skills {
    width: 90%;
  }

  div.skills > div {
    width: 90%;
    margin-bottom: 50px;
    display: inline-block;
    text-align: left;
  }

  div.skills > div:nth-child(odd) {
    margin-right: 0%;
  }

  div.skills > div:nth-child(even) {
    margin-left: 0%;
  }

  div.skills .icon {
    margin-right: 20px;
    margin-bottom: 10px;
    float: left;
  }

  div.skills > div > span:nth-child(2) {
    display: block;
    padding-left: 0px;
    text-indent: 0;
  }
}

#tools_background {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

div.tools {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

div.tools > div {
  width: 44%;
  height: auto;
  min-height: 220px;
  margin-bottom: 4%;
  padding: 2%;
  font-size: 18px;
  color: #2c435e;
  background-color: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex-wrap: wrap;
  text-align: left;
}

div.tools > div:nth-child(odd) {
  margin-right: 2%;
}

div.tools > div:nth-child(even) {
  margin-left: 2%;
}

div.tools span {
  font-family: courier;
  color: #2c435e;
}

@media (max-width: 1000px) {
  div.tools {
    width: 90%;
  }

  div.tools > div {
    width: 90%;
    margin-bottom: 40px;
    padding: 5%;
    height: auto;
  }

  div.tools > div:nth-child(odd) {
    margin-right: 0%;
  }

  div.tools > div:nth-child(even) {
    margin-left: 0%;
  }
}

div.tools .icon {
  color: #fdfefe;
  padding: 10px;
  margin-right: 15px;
  background-color: #2c435e;
  border-radius: 5px;
}

div.tools > div > div:first-child {
  margin-top: 15px;
  margin-bottom: 15px;
}

div.tools > div > div:nth-child(2) {
  padding: 20px 20px 20px 0px;
  font-size: 16px;
  border-radius: 5px;
  text-align: left;
}

div.tools span {
  text-align: left;
}

#projects_background {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

div.projects {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px 20px;
}

div.projects a {
  width: 90%;
  max-width: 350px;
  text-decoration: none;
  border: solid 2px #fff;
  border-radius: 5px;
}

div.projects a:hover {
  border: solid 2px #2c435e;
}

div.projects > a > div {
  padding: 20px;
}

div.projects .thumbnail img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin: 0px auto 20px auto;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#contact_background {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

div.explanatory_text {
  width: 80%;
  max-width: 1000px;
  color: #2c4353;
  text-align: left;
  font-size: 18px;
  margin: 0 auto;
}

p.italicized_note {
  font-size: 16px;
  font-style: italic;
}

p.code_block {
  font-family: monospace;
  margin-left: 40px;
  overflow-x: auto;
  white-space: nowrap;
}

img.static_image {
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: block;
  border: 1px solid #aaa;
  border-radius: 5px;
}

img.small_static_image {
  width: 50%;
  height: auto;
  margin: 0 auto;
  display: block;
  border: 1px solid #aaa;
  border-radius: 5px;
}

img.image_no_border {
  border: none;
}

p.image_citation {
  width: 50%;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 800px) {
  img.static_image {
    width: 100%;
  }

  img.small_static_image {
    width: 100%;
  }

  p.image_citation {
    width: 90%;
  }
}

div.explanatory_text table {
  margin: 0 auto;
  padding: 5px;
  border: 1px solid #888;
  border-radius: 5px;
  text-align: center;
}

div.explanatory_text th {
  margin: 10px;
  padding: 5px;
  background-color: #eee;
}

div.parallax_block {
  width: 100%;
  display: flex;
}

div.parallax_text {
  width: 35%;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
}

div.parallax_image_container {
  width: 60%;
  padding-left: 5%;
  display: relative;
}

img.parallax_image {
  width: 100%;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  border: 1px solid #ddd;
  border-radius: 5px;
}

img.parallax_static_image {
  display: none;
}

@media (max-width: 1300px) {
  div.parallax_text {
    font-size: 18px;
    width: 100%;
    text-align: left;
  }

  div.parallax_image_container {
    display: none;
  }

  img.parallax_image {
    display: none;
  }

  img.parallax_static_image {
    width: 90%;
    height: auto;
    margin: 0 auto;
    display: block;
    border: 2px solid #aaa;
    border-radius: 5px;
  }
}

div.parallax_spacer {
  width: 100%;
  height: 400px;
  background-color: inherit;
}

div.parallax_image_spacer {
  width: 100%;
  height: 0px;
  background-color: inherit;
}

@media (max-width: 1300px) {
  div.parallax_spacer {
    height: 100px;
  }

  div.parallax_image_spacer {
    height: 30px;
  }
}
